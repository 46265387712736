import config from "@/config"
const tool = {
    /* 日期格式化 */
    dateFormat(date, fmt = 'yyyy-MM-dd') {
        if (date == null) {
            date = new Date()
        } else {
            date = new Date(date)
        }

        var o = {
            "M+": date.getMonth() + 1,                 //月份
            "d+": date.getDate(),                    //日
            "h+": date.getHours(),                   //小时
            "m+": date.getMinutes(),                 //分
            "s+": date.getSeconds(),                 //秒
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度
            "S": date.getMilliseconds()             //毫秒
        };
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        }
        for (var k in o) {
            if (new RegExp("(" + k + ")").test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
            }
        }
        return fmt;
    },
    getMediaUrl(url) {
        return `${config.STATIC_URL}` + url
    },
    download(url) {
        window.location.href = this.getMediaUrl(url)
    },

    // 去重 [{}, {}, {}, {}, {}] 格式
    removeDuplicates(array, field) {
        const seen = {}; // 创建一个空对象来跟踪已经遇到的字段值
        const result = [];
        array.forEach(item => {
            const value = item[field]; // 获取对象的字段值
            if (!seen[value]) { // 如果该值尚未在跟踪对象中出现
                seen[value] = true; // 将其添加到跟踪对象中
                result.push(item); // 将对象添加到结果数组中
            }
        });

        return result;
    },
}

/* 防抖节流函数 */
let timeout = null // 创建一个标记用来存放定时器的返回值
let canRun = true;
let count = 0;

let dbCount = 0;
//!!!!这里一定要把变量设在外面，不然会失效！我搞了好久才搞明白！
export function debounce(fn, time=1000, atOnce=true) {
    return function () {
        if (atOnce && dbCount === 0) {
            fn.apply(this, arguments)
            dbCount++;
            return
        }
        // if (dbCount === 1) return
        if (timeout) {
            clearTimeout(timeout) // 每当用户输入的时候把前一个 setTimeout clear 掉 
            timeout = null;
        }
        timeout = setTimeout(() => { // 然后又创建一个新的 setTimeout, 这样就能保证输入字符后的 interval 间隔内如果还有字符输入的话，就不会执行 fn 函数
            fn.apply(this, arguments)
            dbCount = 0;
        }, time)
    }
}

export function throttle(fn, time = 1000, atOnce = true) {
    return function () {
        if (atOnce) { // 立即执行一次
            if (count === 0) {
                fn.apply(this, arguments)
                count++
            }
        }
        
        if (!canRun) return
        canRun = false
        setTimeout(function () {
            if (atOnce && count === 1) {
                count = 0
                canRun = true;
                return
            }
            // fn.apply(this, arguments)
            // canRun = true;
            // count = 0;
        }, time);
    }
}



export default tool
