<template>
    <div class="alarmBox">
        <!-- <div class="detaiBox"> 
            <div class="alarmNews" v-for="(item,index) in alarm_data "  :key="index">
                <div class="title">
                    {{ item.label }}:
                </div>
                <el-tooltip class="item" effect="dark" :content="item.value+''" placement="top-start">
                    <div class="text" v-if="item.value">
                        {{ item.value }}
                    </div>
                    <div class="text" v-else>
                        --
                    </div>
                </el-tooltip> 
            </div>
        </div> -->  
        <el-tabs v-model="activeName">
            <el-tab-pane label="报警信息" name="first">
            </el-tab-pane>
            <el-tab-pane label="报警图片" name="second" >
            </el-tab-pane>
        </el-tabs>

        <div v-show="activeName === 'first'" class="wh100p">
            <div class="alarmDetailMap">
                <div class="centerAlarm">
                    <div class="alarmBasic">
                        <div class="title">
                            设备基本信息
                        </div>
                        <div style="width: 100%;height: 70%;display: flex;flex-wrap: wrap;">
                            <div class="basicLsit" v-for="item in basiceNeswList" :key="item.id">
                                <div class="text">
                                    {{ item.title }}:
                                </div>
                                <el-tooltip class="item" effect="dark" :content="item.text" placement="top-start">
                                    <div class="textNews">
                                        {{ item.text }}
                                    </div>
                                </el-tooltip>
                            </div>
                        </div>
                    
                    </div>  
                    <div class="deivceBox">
                        <div class="title">
                            设备数据
                        </div>
                        <div style="width: 100%;height: 70%;display: flex;flex-wrap: wrap;align-content:flex-start;box-sizing: border-box;padding-top: 10px;">
                            <div class="deviceList" v-for="item in alarm_data" :key="item.id">
                                <div class="text">
                                    {{ item.label }}:
                                </div>
                                <div class="textNews" v-if="item.value">
                                    {{ item.value }}
                                </div>
                                <div class="textNews" v-else>
                                    --
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 50%;height: 100%;">
                    <alarMap ref="alarMap" :position="mapPosition"></alarMap>
                </div>
                
            </div>
            <div class="clearBox" style="padding-top: 10px;">
                <el-form :model="disposeAlarm" :inline="true" :rules="rules" ref="ruleForm" label-width="120px" style="height: 70px;">
                    <el-form-item :label='$t("basic.alarmPlatform.alarmReason")' prop="cause">
                        <el-select v-model="disposeAlarm.cause" clearable filterable :placeholder='$t("basic.alarmPlatform.alarmReasonPla")' size="small">
                            <el-option
                            v-for="item in AlarmCause"
                            :key="item.id"
                            :label="item.type_name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label='$t("basic.alarmPlatform.remark")' prop="remarks">
                        <el-input type="textarea" :rows="2" v-model="disposeAlarm.remarks" style="min-width: 400px;"></el-input>
                    </el-form-item>
                    <el-form-item style="width: 100%;display: flex;justify-content: center;">
                        <el-button type="primary" @click="submitForm('ruleForm')">{{$t("basic.alarmPlatform.handleAlarm")}} </el-button>
                        <el-button @click="resetForm('ruleForm')">{{$t("basic.alarmPlatform.rest")}} </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <div v-show="activeName === 'second'" class="wh100p">
            <ImgCom :device_number="allAlarmData.device_number" ref="imgCom" style="height: 100%" maxHeight="480px"></ImgCom>
        </div>
       
    </div>
</template>
<script>
import alarMap from "@/views/NewestHomePage/AlarmPlatform/map/TwoMap.vue"
import messageAll from '@/public_Js/messageAll';
import ImgCom from "@/views/device/deviceList/tabLIst/videoInformation.vue"
export default {
    components:{
        alarMap,
        ImgCom,
    },
    props:{
        alarm_data:{
        //   type: Object,
            type: Array,
            default:()=>{
                []
            }
        },
        mapPosition:{
            type: Object,
            default:()=>{
                return {}
            }
        },
        allAlarmData:{}
    },
    data(){
        const noSpaces = (rule, value, callback) => {
            if (/\s/.test(value)) {
                callback(new Error(this.$t("basic.all.AlarmPlatform_noSpacing")));
            } else {
                callback();
            }
        };
        return{
            TwoPositio:'',
            activeName: 'first',
            disposeAlarm:{
                cause:'',
                remarks:''
            },
            rules:{
                cause:[
                    { required: true, message: '请选择处理方式', trigger: 'blur' },
                ],
                remarks:[
                    { required: true, message: '请输入备注', trigger: 'blur' },
                    { validator: noSpaces, trigger: 'blur' }
                ]
            },
            AlarmCause: [],
            basiceNeswList:[
                { id:1, title:'设备编码', text:'--' },
                { id:2, title:'安装位置', text:'--' },
                { id:3, title:'设备型号', text:'--' },
                { id:4, title:'设备类型', text:'--' },
                { id:5, title:'所属单位', text:'--' },
                { id:6, title:'报警时间', text:'--' },
                { id:7, title:'联系人', text:'--' },
                { id:8, title:'联系电话', text:'--' },
            ]

        }
    },
    mounted(){
        this.$refs.alarMap.initMap("中国",2)
        this.getAlarmReason()
        this.getAlarmNews()
        
    },
    methods:{
        // 获得接警处理原因
        async getAlarmReason(){
            let res =  await  this.$API.alarm.alarmReason.get({})
            this.AlarmCause = res.data
        },

        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        // 处理
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                    this.$API.AlarmMap.configAlarm.patch(this.allAlarmData.alarm_id,{
                    remark:this.disposeAlarm.remarks==""?"无":this.disposeAlarm.remarks,
                    check_type_id:this.disposeAlarm.cause,
                }).then((res)=>{
                    if(res.code != 200){
                        messageAll.errorOpen(this.$t("basic.alarmPlatform.mess_handleAlarmLose"))
                        
                    }else {
                        messageAll.successOpen(this.$t("basic.alarmPlatform.mess_handleAlarmSuccess"))
                        this.$store.commit('mqttIsHandle')
                        this.$emit('clearAlarm',this.allAlarmData.device_number, this.allAlarmData.alarm_id)
                    }
                 
                })
            } else {
                return false;
            }
            });
        },

        // 信息数据赋值
        getAlarmNews(){
            let res = this.allAlarmData
            this.basiceNeswList[0].text = res.device_number
            this.basiceNeswList[1].text = res.address
            this.basiceNeswList[2].text = res.device_model
            this.basiceNeswList[3].text = res.device_type
            this.basiceNeswList[4].text = res.entity_name
            this.basiceNeswList[5].text = res.device_data.time
            this.basiceNeswList[6].text = res.owner.contactName
            this.basiceNeswList[7].text = res.owner.contactrPhone
            this.$nextTick(() => {
                this.$refs.imgCom.getTable(res.device_number);
            });
        }
    }
}
</script>
<style  lang="scss" scope>
.alarmBox{
    width:100%;
    height:100%;
    font-size: calc(100vw * 14 / 1920);
    
    .detaiBox{
        width:100%;
        max-height:15%;
        display:flex;
        padding-bottom: 15px;
        .alarmNews{
            width:20%;
            height:15%;
            display:flex;
            // background-color: aqua;
            .title{
                width: 30%;
            }
            .text{
                width: 70%;
            }
        }
    }
    .clearBox{
        width: 100%;
        height: 20%;
    }
    .alarmDetailMap{
        width:100%;
        height:70%;
        display: flex;
        .centerAlarm{
            width: 50%;
            height: 100%;
            .alarmBasic{
                width: 100%;
                height: 40%;
                .title{
                    width: 100%;
                    font-size: 22px;
                    font-weight: 600px;
                    height: 30%;
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                    padding-left: 20px;
                    border-radius: 8px 0 0 8px;
                    background: linear-gradient(90deg,rgba(83, 210, 248, 0.8),rgb(255, 255, 255, ));
                }
                .basicLsit{
                    width: 50%;
                    height: 27%;
                    display: flex;
                    align-items: center;
                    .text{
                        width: 25%;
                        color:  #999595;
                    }
                    .textNews{
                        width: 75%;
                        box-sizing: border-box;
                        padding-right: 12px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        color: rgb(0, 0, 0);
                    }
                }
            }
            .deivceBox{
                width: 100%;
                height: 60%;
                align-content:flex-start;
                padding-top: 20px;
                box-sizing: border-box;
                .title{
                    box-sizing: border-box;
                    width: 100%;
                    font-size: 22px;
                    font-weight: 600px;
                    height: 15%;
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                    border-radius: 8px 0 0 8px;
                    padding-left: 20px;
                    background: linear-gradient(90deg,rgba(83, 210, 248, 0.8),rgb(255, 255, 255, ));
                }
                .deviceList{
                    width: 50%;
                    height: 20%;
                    display: flex;;
                    .text{
                        width: 25%;
                        color:  #999595;
                    }
                    .textNews{
                        width: 75%;
                        color: black;
                    }
                }
            }

        }
    }
    ::v-deep .el-tabs__content{
        width: 100% !important;
        height: 100% !important;
    }
}
</style>