<template>
    <div class="device-type-stats"  @mouseenter="mouse(1)" @mouseleave="mouse(2)">
        <div class="center-icon"  v-show="showIcon">
            <i class="iconfont icon-shexiangtou" style="font-size: 28px;"></i>
            <div>{{windowName}}{{ showIcon?playIndex===-1? "" : playIndex + 1 : "" }}</div>
        </div>
        
        <!-- 视频播放容器 -->
        <div id="video" ref="video"> </div>

        <div class="header-title" :style="{fontSize: showPoint? '14px' : '0'}" >{{ cameraName }}</div>

        <div v-show="isPlaying" :class="[isHover?'hoverMask':'', 'control-mask']">
            <div class="df aic jcc wh100p" v-if="!isAlarm">
                <div class="mask-item" v-for="item in normalBtn" :key="item.id" @click="maskControl(item.id)" >
                    <i :class="item.icon" style="font-size: 28px;"></i>
                    <div>{{ item.title }}</div>
                </div>
            </div>

            <div class="df aic jcc wh100p" v-else>
                <div class="mask-item" v-for="item in alarmBtn" :key="item.id" @click="maskControl(item.id)" v-show="!showVolume" >
                    <i :class="item.icon" style="font-size: 28px;"></i>
                    <div>{{ item.title }}</div>
                </div>

                <div v-show="showVolume" class="df aic jcc">
                    <div>音量: </div>
                    <div class="ml6 mr10 w200px"><el-slider v-model="volum" :min="0" :max="1" :step="0.01" @change="changeVolumn"></el-slider></div>
                    <div @click="showVolume = false" style="cursor: pointer;"> <i class="el-icon-arrow-left mr6"></i>返回</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DemoPlayer",
    component: {
    },
    props: {
        // url: { type: String, default: "" },
        playIndex: { type: Number, default: -1 },
        cameraName: { type: String, default: "" },
        showPoint: { type: Boolean, default: false },
        isAlarm: { type: Boolean, default: false }, // 是否报警
        isRecord: { type: Boolean, default: false }, // 是否回放
    },
    watch: {
        url() {
            // this.play();
        },
        isAlarm() { this.cancelMute() },
        
    },
    data() {
        return {
            showCtrl: false,
            showIcon: true,
            isPlaying: false,
            showVolume: false,
            volum: 0.8,
            isHover: true,
            windowName: "窗口",
            jessibuca: null,
            isVolume: false, // 默认关闭声音
            normalBtn: [
                // { id: 2, title: "安装位置", icon: "el-icon-location-information" },
                { id: 1, title: "接警", icon: "el-icon-microphone" },
                { id: 3, title: "左旋转", icon: "iconfont icon-zuoxuanzhuan" },
                { id: 4, title: "右旋转", icon: "iconfont icon-rotate-right" },
                { id: 9, title: "声音", icon: "iconfont icon-bell-off" },
                { id: 2, title: "关闭", icon: "el-icon-switch-button" },
            ],
            angle: 0,
            alarmBtn: [
                { id: 5, title: "录制", icon: "el-icon-video-camera-solid", state: false },
                { id: 6, title: "截图", icon: "el-icon-scissors" },
                // { id: 3, title: "广播", icon: "iconfont icon-zuoxuanzhuan" },
                { id: 7, title: "全屏", icon: "el-icon-full-screen" },
                { id: 10, title: "声音", icon: "iconfont icon-bell-fill" },
                { id: 8, title: "刷新", icon: "el-icon-refresh" },
            ],
            reloadCount: 0, // 播放失败重播次数
            // showPoint: false,
            // cameraName: "摄像头名称",
        };
    },
    beforeDestroy() {
        // 关闭前
        this.destroy();
    },
    methods: {
        // 改变音量
        changeVolumn(val) { 
            this.jessibuca.setVolume(val);
            let icon, title = "";
            if (val === 0) {
                icon = "iconfont icon-bell-fill";
                title = "静音";
            } else {
                icon = "iconfont icon-bell-fill";
                title = "静音";
            }
            this.alarmBtn[3].icon = icon;
            this.alarmBtn[3].title = title;
        },
        // 遮罩层按钮
        maskControl(val) { 
            if (val === 3 || val === 4) this.rotate(val);
            else if (val === 1) this.takeOnThAlarm();
            else if (val === 2) this.closeAlarm();
            else if (val === 5) this.record(this.alarmBtn[0].state);
            else if (val === 6) this.jessibuca.screenshot();
            else if (val === 7) this.jessibuca.setFullscreen(true)
            else if (val === 8) this.$emit("refresh");
            else if (val === 9) this.volumeSet();
            else if (val === 10) this.takeVolume();
        },
        takeVolume() { 
            this.showVolume = true;
        },
        // 声音设置
        volumeSet() { 
            this.isVolume = !this.isVolume;
            this.normalBtn[3].icon = this.isVolume ? "iconfont icon-bell-fill" : 'iconfont icon-bell-off';
            this.normalBtn[3].title = this.isVolume ? "扬声器" : '静音';
            // 为true取消静音，为false设置静音
            this.isVolume ? this.cancelMute() : this.mute();
        },
        // 接警
        takeOnThAlarm() {
            this.$emit("openReceiveAlarm")
        },
        // 旋转
        rotate(val) {
            if (val === 3) {
                if (this.angle === 0) this.angle = 270;
                else if (this.angle === 270) this.angle = 180;
                else if (this.angle === 180) this.angle = 90;
                else if (this.angle === 90) this.angle = 0;
            } else if (val === 4) {
                this.angle = this.angle > 270 ? 0 : this.angle + 90;
            }
            this.jessibuca.setRotate(this.angle);
        },
        // 录制
        record(state) {
            let status = !state;
            this.alarmBtn[0].state = status;
            status ? this.alarmBtn[0].title = "停止录制" : this.alarmBtn[0].title = "开始录制";
            if (status) this.jessibuca.startRecord();
            else this.jessibuca.stopRecordAndSave();
        },
        // 关闭
        closeAlarm() {
            this.$confirm(`是否关闭窗口${this.playIndex + 1}播放的视频?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.destroy();
                this.alarmBtn[0].state = false;
                this.alarmBtn[0].title = '录制';
                this.isPlaying = false;
                this.windowName = "窗口";
                this.$emit("close", this.playIndex);
            })
            
        },

        mouse(type) {
            if (this.isRecord) return; // 回放时不显示遮罩层上的控制按钮
            if (!this.jessibuca) return;
            if (!this.isPlaying) return;
            if (type === 1) {
                this.isHover = false;
            } else if (type === 2) {
                this.isHover = true;
            }
        },
        // 实例化 jessibuca 插件
        create() {
            let jessibucaDemo = null;
            jessibucaDemo = {
                container: this.$refs.video,
                videoBuffer: 0.2, // 设置最大缓冲时长，单位秒，播放器会自动消除延迟。
                videoBufferDelay: 1, // 视频缓冲区延迟
                isResize: false, // 当为true的时候：视频画面做等比缩放后,高或宽对齐canvas区域,画面不被拉伸,但有黑边。
                text: "",
                hiddenAutoPause: false,
                loadingText: this.$t("cameraSetting.loading"), //  加载过程中文案。
                debug: false, //
                debugLevel: "debug",
                useMSE: false, // 否开启MediaSource硬解码
                decoderErrorAutoWasm: false,
                useSIMD: false,
                useWCS: false, // 否开启Webcodecs硬解码
                useMThreading: true,
                showBandwidth: this.showCtrl, // 显示网速
                showPerformance: false,  // 显示性能
                operateBtns: {  // 配置操作按钮
                    fullscreen: this.showCtrl,  // fullscreen 是否显示全屏按钮
                    screenshot: this.showCtrl,   // screenshot 是否显示截图按钮
                    play: false,     // play 是否显示播放暂停按钮
                    audio: this.showCtrl,    // audio 是否显示声音按钮
                    ptz: false,  // 是否显示 上下左右控制器按钮
                    record: this.showCtrl,    // record 是否显示录制按钮
                    // performance:true, // 是否显示性能按钮
                    zoom: this.showCtrl, // 是否显示 放大缩小
                },
                forceNoOffscreen: true,     //是否不使用离屏模式（提升渲染能力）
                isNotMute: false,
                timeout: 10000,  // 设置超时时长, 单位秒
                heartTimeoutReplayUseLastFrameShow: true,
                audioEngine: "worklet",
                heartTimeout: 5,   // 设置心跳时长, 单位秒
                heartTimeoutReplay: true,
                heartTimeoutReplayTimes: -1, // 无限重复拉流
                loadingTimeout: 10, //
                loadingTImeoutReplay: true,
                loadingTImeoutReplayTimes: -1,
                ptzClickType: "mouseDownAndUp", //// 控制器类型: 点击显示
                ptzZoomShow: false, // 视频控制器按钮 左上, 右上, 右下, 左下
                ptzMoreArrowShow: false, // 视频控制器按钮 左上, 右上, 右下, 左下
                ptzApertureShow: false, // 视频控制器按钮 左上, 右上, 右下, 左下
                ptzFocusShow: false, // 视频控制器按钮 左上, 右上, 右下, 左下
                useCanvasRender: false,
                useWebGPU: true,
                controlAutoHide: true, // 控制按钮是否自动隐藏
            }
            this.jessibuca = new window.JessibucaPro(jessibucaDemo);
            this.jessibuca.on(JessibucaPro.EVENTS.playFailedAndPaused, () => {
                // this.windowName = "播放失败"
                this.showIcon = true;
                this.isPlaying = false;
                this.reloadCount += 1;
                if (this.reloadCount === 1) {
                    this.$emit("playFail", this.playIndex)
                } else if (this.reloadCount === 2) {
                    this.$emit("playErr", this.playIndex)
                    this.destroy();
                    this.reloadCount = 0
                }

            })
            this.jessibuca.on("play", () => {
                if (this.isRecord) return;
                this.isPlaying = true;
                this.$emit("beginPlay", this.playIndex)
            })
        },
        // 播放视频
        play(url) {
            // url = "https://live.nodemedia.cn:8443/live/b480_264.flv";
            if (!url) return;
            this.showIcon = false;
            this.url = url;
            if (this.jessibuca) {
                this.jessibuca.destroy().then(() => {
                    this.create();
                    this.jessibuca.play(url);
                });
            } else {
                this.create();
                this.jessibuca.play(url);
                this.reloadCount = 0
            }
        },
        setMute() {
            this.jessibuca.cancelMute();
            this.jessibuca.setVolume(0.8);
        },
        // 触发声音事件
        mute() {this.jessibuca.mute()},
        // 取消静音
        cancelMute() { 
            this.jessibuca.cancelMute()
        },
        // 暂停
        pause() { this.jessibuca.pause() },
        // 销毁
        destroy() {
            if (!this.jessibuca) return;
            var result = this.jessibuca.isRecording()
            if( result ){
                this.jessibuca.stopRecordAndSave();
            }
            this.jessibuca.cancelMute()
            this.jessibuca.destroy().then(() => {
                this.jessibuca = null;
                this.showIcon = true;
            });
        },
        // 截图
        screenShot() {
            try {
                const imgData = this.jessibuca.screenshot("test", "png", 0.92, 'base64');
                return imgData
            } catch (e) {
                return null
            }
        },
        close() {
            if (this.jessibuca) {
                this.jessibuca.close();
            }
        },
        recording() {
            return this.jessibuca.isRecording();
        },
       
    },
    
};
</script>

<style lang="scss" scoped>


.device-type-stats {
    height: 100%;
    width: 100%;
    // background-color: rgba(243, 250, 250, .2);
    border-radius: 2px;
    backdrop-filter: blur(1px);
    position: relative;
}
.center-icon{
    z-index: 10;
    width: 80px;
    height: 80px;
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    color: white;
    text-align: center;
    line-height: 30px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}
#video {
    //  min-width: 300px;
    //  min-height: 500px;
    height: 100%;
    width: 100%;
}
.control-mask{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    color: #fff;
    line-height: 20px;
    opacity: 1;
    transition: all .7s;
    
    // .normal{
    //     width: 100%;
    //     height: 100%;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    // }
    .mask-item{
        margin: 0 5px;
        width: 80px;
        height: 80px;
        // background-color: #fff;
        text-align: center;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        :hover{
            cursor: pointer;
        }
    }
    
    .w200px{
        width: 200px;
    }
}
.header-title{
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    padding-left: 10px;
    padding-top: 0;
    text-align: right;
    z-index: 10086;
    box-sizing: border-box;
    color: #eee;
    font-size: 16px;
    font-weight: bold;
    transition: all .3s;
    line-height: 20px;
}
.hoverMask{
    opacity: 0;
}
</style>
