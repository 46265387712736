<template>
    <div class="alarmDialog">
        <!-- @click="receiveAlarm(item, index)" -->
        <div 
            class="window" 
            v-for="(item, index) in alarmData" 
            :key="index"  
            :style="{width: alarmArr.length === 1 ? `100%` : `calc(50% - 0.5px)`}"
            >
            
            <!-- :key="alarmIndex" -->
            <div :key="alarmIndex + 1" style="background-color: #fff; height: 20px; overflow: hidden;" v-show="false">{{ item }}</div>
            <div class="alarm-info" v-if="item !== null">
                <div class="info-value">设备编码: {{ item.device_number }}</div>
                <div class="info-value">设备类型: {{ item.device_type }}</div>
                <div class="info-value">发生时间: {{ item.time }}</div>
                <div class="info-value">业主信息: {{ item.ownerName }}  {{ item.ownerPhone }}</div>
                <div class="info-value">安装场所: {{ item.address }}</div>
                <div class="info-value df aic" >
                    <div v-if="timerArr[index]">告警倒计时：<span :key="time_key['w'+index]">{{ timerArr[index].str }}</span></div>
                    <div class="ml10"><el-button size="mini" type="primary" @click="refreshVideo(index)">刷 新</el-button></div>
                </div>
            </div>
            
            <div class="video-box">
                <player :ref="'player'+index" :playIndex="index" @openReceiveAlarm="openReceiveAlarm(item, index)" @close="close(index)" @playFail="refreshVideo" @playErr="playErr"></player>
            </div>
        </div>
        
        <el-dialog
            :visible.sync="receiveDialog"
            destroy-on-close 
            :show-close="false" 
            :close-on-click-modal="false"
            width="95%"
            @close="closeReceiveDialog"
            append-to-body>
            <div class="alarm-title" slot="title">
                <div>接警</div>
                <div @click="closeAlarmPlat"><i class="el-icon-close" style="cursor: pointer;"></i></div>
            </div> 
            <div class="content-box">
                <div class="left-player" >
                    <player ref="player" isAlarm @refresh="refresh"></player>
                </div>
                <div class="right-handle">
                    <!-- 右侧详情显示以及报警处理 -->
                    <div class="right-data-box">
                        <div class="data-title">设备基本信息</div>
                        <ul class="device-info-box">
                            <li v-for="(item, index) in deviceInfo" :key="index">
                                <div>{{ item.title }}</div>
                                <div>{{ receiveData[item.type] }}</div>
                            </li>
                            <li>
                                <div>报警时间：</div>
                                <div>{{ receiveData.time }}</div> 
                            </li>
                            <li>
                                <div>联 系 人：</div>
                                <div>{{ receiveData.ownerName }}</div> 
                            </li>
                            <li>
                                <div>联系电话：</div>
                                <div>{{ receiveData.ownerPhone }}</div>
                            </li>
                        </ul>
                        <div class="device-title">事件处理</div>
                        
                        <div class="alarm-cause-box">
                            <div class="alarm-case-radio">
                                <el-select v-model="alarmCauseRadio" placeholder="请选择处理方式" style="width: 100%;">
                                    <el-option
                                    v-for="item in AlarmCause"
                                        :key="item.id"
                                        :label="item.type_name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                            <el-form :model="disposeAlarm"  :rules="rules" ref="ruleForm" >
                                <el-form-item prop="remarks" style="margin-bottom: 20px; margin-top: 10px; width: 100%;">
                                    <el-input type="textarea" style="width: 100%;" v-model="disposeAlarm.remarks" placeholder="请输入备注"></el-input>
                                </el-form-item>
                                <el-form-item style="margin-bottom: 0;text-align: center;">
                                    <el-button type="primary" @click="HandleAlarms('ruleForm')">{{
                                        $t("basic.alarmPlatform.handleAlarm") }} </el-button>
                                    <el-button @click="resetForm('ruleForm')">{{ $t("basic.alarmPlatform.rest") }} </el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>

        <video id='myVideo' controls autoplay v-show="false"></video>
        <video id='mySelfVideo' controls autoplay v-show="false"></video>
    </div>
</template>

<script>
import messageAll from '@/public_Js/messageAll';
import player from '@/views/MqttVideo/components/player.vue';
export default {
    components: { player },
    props: {
        alarmArr: {
            type: Array,
            default() {
                return []
            }
        },
        alarmWindowIndex: {
            typeof: Number,
            default() {
                return -1
            }
        }
    },
    watch: {
        alarmData: {
            handler(val) { 
                for (let i = 0; i < val.length; i++) { 
                    if (!val[i]) continue;
                    let url = "";
                    if (document.location.protocol === "http:") {
                        url = `ws://zlm.tranthing.com:80/rtp/${val[i].device_number}_${val[i].device_number}.live.flv`;
                    } else {
                        url = `wss://zlm.tranthing.com:443/rtp/${val[i].device_number}_${val[i].device_number}.live.flv`;
                    }
                    this.alarmData[i].url = url;
                    this.$nextTick(() => {
                        this.$refs['player' + i][0].play(url);
                    })
                }
                for (let i = 0; i < val.length; i++) {
                    if (!val[i]) continue;
                    this.playVideo(val[i].device_id, "START");
                    this.timerOut(i)
                }
            },
            deep: true,
            immediate: true
        },
        alarmArr: {
            handler(val) {
                this.alarmData = val;
            },
            deep: true,
            immediate: true
        },
        alarmWindowIndex(nv) {
            console.log("nv", nv)
            // this.refreshVideo(nv);
        },
        receiveDialog(nv) {
            console.log("nv", nv)
        },
        
    },
    data() {
        const noSpaces = (rule, value, callback) => {
            if (/\s/.test(value)) {
                callback(new Error(this.$t("basic.all.AlarmPlatform_noSpacing")));
            } else {
                callback();
            }
        };
        return {
            windowData: [
                { id: 1, name: "窗口1", status: true },
                { id: 2, name: "窗口2", status: true },
                { id: 3, name: "窗口3", status: true },
                { id: 4, name: "窗口4", status: true },
            ],
            windowIndex: -1,
            timers: [],
            receiveData: {}, // 接警数据
            receiveIndex: -1, // 接警窗口下标
            receiveDialog: false, // 接警弹窗
            deviceInfo: [
                {title: "设备编码: ", type: "device_number" },
                {title: "设备型号: ", type: "device_model" },
                {title: "设备类型: ", type: "device_type" },
                {title: "所属单位: ", type: "entity_name" },
            ],
            AlarmCause: [],
            alarmCauseRadio: '',
            disposeAlarm: {
                cause: '',
                remarks: ''
            },
            showPlayerIcon: true,
            rules: {
                cause: [{ required: true, message: '请选择处理方式', trigger: 'blur' }],
                remarks: [
                    { required: true, message: '请输入备注', trigger: 'blur' },
                    { validator: noSpaces, trigger: 'blur' }
                ]
            },
            rtcState: false, // 对讲状态
            urlTimeStamp: new Date().getTime(),
            rtcPlayer: null, // rtc对讲实例
            alarmIndex: 0,
            timerArr: [null, null, null, null],
            time_key: {
                w0: "",
                w1: "",
                w2: "",
                w3: "",
            },
            alarmData: [null, null, null, null],
        }
    },
    mounted() { 
        this.getAlarmReason();
    },
    async beforeDestroy() { 
        if (!this.rtcPlayer) return
        let startData = {
            "cmd": "CMD+START",
            "url": this.$CONFIG.STATIC_LIVE_URL + this.urlTimeStamp,
        }
        // 开始对讲
        await this.requestPublicFn("gb28181Intercom", startData);
    },
    methods: {
        // 视频播放失败，视频窗口已被销毁
        playErr(index) {
            if (this.timerArr[index]) {
                clearInterval(this.timerArr[index].timer)
                this.timerArr[index].timer = null;
                this.timerArr[index] = null;
                this.time_key['w' + index] = Date.now() + "";
            }
        },
        // 倒计时
        timerOut(index) {
            if (this.timerArr[index]) {
                clearInterval(this.timerArr[index].timer)
                this.timerArr[index].timer = null;
                this.timerArr[index] = null;
            }
            this.timerArr[index] = { str: '05 分 00 秒', time: 1000 * 60 * 5 },
            this.timerArr[index].timer = setInterval(() => {
                this.timerArr[index].time -= 1000;
                // 将毫秒转换为分钟和秒
                let minutes = Math.floor(this.timerArr[index].time / 60000) + ''; // 计算分钟
                let seconds = Math.floor((this.timerArr[index].time % 60000) / 1000) + ''; // 计算剩余秒数
                minutes = minutes.length === 1 ? '0' + minutes : minutes;
                seconds = seconds.length === 1 ? '0' + seconds : seconds;
                // 输出当前时间
                this.timerArr[index].str = `${minutes} 分 ${seconds} 秒`;
                this.time_key['w' + index] = this.timerArr[index].str
                // 倒计时为0，倒计时结束
                if (this.timerArr[index].time <= 0) {
                    clearInterval(this.timerArr[index].timer);
                    this.timerArr[index].timer = null;
                    this.timerArr[index] = null;
                    if (this.$refs['player' + index][0]) {
                        this.$refs['player' + index][0].destroy();
                    }
                    this.close(index)
                }
            }, 1000)
        },
        // 关闭接警平台
        closeAlarmPlat() { 
            this.receiveDialog = false;
            let length = this.alarmData.filter(item => item !== null).length;
            console.log("this.alarmArr.length", this.alarmArr.length)
            if (this.alarmArr.length === 1 || length === 0) {
                this.$emit("close")
            }
            this.closeRTC();
        },
        // 刷新按钮 或 播放失败之后，重新报警视频窗口重新播放重新播放
        async refreshVideo(index) { 
            let data = this.alarmData[index];
            let id = data.device_id;
            this.timerOut(index);
            this.$refs['player' + index][0].play(data.url)
            let params = JSON.stringify({ cmd: "CMD+" + "START" })
            this.alarmIndex += 1;
            let res = await this.$API.cameraJump.playGB28181Live.post(id, params);
            if (!res.data || res.data.code !== 200) {
                messageAll.warningOpen("获取播放地址失败");
                return
            }
            // this.$refs['player' + index][0].play(hrefs)
        },
        // 刷新
        async refresh() {
            let id = this.receiveData.device_id
            this.$refs.player.play(this.receiveData.url);
            let hrefs = await this.playVideo(id, "START");
            this.$refs.player.play(hrefs);
        },
        close(index) {
            this.$store.commit("setAlarmType", false);
            window.speechSynthesis.cancel();
            this.alarmData[index] = null;
            this.alarmIndex += 1;
            if (this.timerArr[index]) {
                clearInterval(this.timerArr[index].timer);
                this.timerArr[index] = null;
            }
            let flag = this.timerArr.filter(item => item != null);
            if (flag.length === 0) {
                this.$emit("receiveAlarm", index)
            }
            // if(this.timerArr)
            
        },
        // 接警 打开接警大屏弹窗
        openReceiveAlarm(item, index) { 
            this.receiveData = this.alarmData[index];
            this.receiveIndex = index;
            this.receiveDialog = true;
            this.$nextTick(async () => {
                this.$refs.player.play(this.receiveData.url);
                this.$refs.player.setMute();
                this.$refs['player' + index][0].destroy();
                this.showPlayerIcon = false;
                this.rtcState = true;
                this.$store.commit("setAlarmType", false);
                window.speechSynthesis.cancel();
                this.alarmData[index] = null
                this.microphoneFn();
                this.$emit("closeAudio", index);
                this.alarmIndex += 1;
                if (this.timerArr[index]) {
                    clearInterval(this.timerArr[index].timer);
                    this.timerArr[index] = null;
                }
                
            })
        },
        activeWindow(item, index) {
            if (this.windowIndex === index) {
                this.windowIndex = -1;
                return;
            }
            this.windowIndex = index;
        },
        async getAlarmReason() {
            let res = await this.$API.alarm.alarmReason.get({});
            this.AlarmCause = res.data;
        },
        HandleAlarms(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$API.AlarmMap.configAlarm.patch(this.receiveData.alarm_id, {
                        remark: this.disposeAlarm.remarks == "" ? "无" : this.disposeAlarm.remarks,
                        check_type_id: this.alarmCauseRadio,
                    }).then((res) => {
                        if (res.code != 200) {
                            messageAll.errorOpen(this.$t("basic.alarmPlatform.mess_handleAlarmLose"));
                        } else {
                            messageAll.successOpen(this.$t("basic.alarmPlatform.mess_handleAlarmSuccess"))
                            this.receiveDialog = false;
                            this.showPlayerIcon = true;
                            this.closeRTC();
                            this.$store.commit('mqttIsHandle');
                            // this.$emit("handleAlarm");
                            // this.closeAlarmWindow();
                            // this.$emit('clearAlarm', this.allAlarmData.device_number, this.allAlarmData.alarm_id)
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetField();
        },
        // 关闭报警弹窗
        closeReceiveDialog() {
            let index = this.receiveIndex;
            this.$refs['player'+index][0].isPlaying = false;
            let id = this.receiveData.device_id;
            this.playVideo(id, "STOP");
            
        },

        // 麦克风事件
        async microphoneFn() {
            this.urlTimeStamp = new Date().getTime();
            this.start_play();
            if( this.rtcPlayer ) {
                let startData = {
                    "cmd": "CMD+START",
                    "url": this.$CONFIG.STATIC_LIVE_URL + this.urlTimeStamp,
                }
                // 开始对讲
                await this.requestPublicFn("gb28181Intercom", startData);
                // this.braadcast = false;
            } else {
                messageAll.warningOpen(this.$t('cameraSetting.notImplemented'))
            }
        },

        // 开始对讲
        start_play(){
            let url = new URL(`${this.$CONFIG.STATIC_LIVE}&stream=${this.urlTimeStamp}&type=push`);
            this.rtcPlayer = new ZLMRTCClient.Endpoint({
                element: document.getElementById('myVideo'),// video 标签
                debug: true,// 是否打印日志
                zlmsdpUrl: url,//流地址
                simulcast: false,
                useCamera: false,
                audioEnable: true,
                videoEnable: false,
                recvOnly: false,
                resolution: { w: 1920, h: 1080 },
                usedatachannel: false,
            });
        },
        
        // 关闭对讲功能
        async closeRTC() {
            if (!this.rtcPlayer) return 
            this.rtcPlayer.close();
            this.rtcPlayer.closeDataChannel();
            this.rtcPlayer = null;
            var remote = document.getElementById('myVideo');
            if(remote) {
                remote.srcObject = null;
                remote.load();
            }
            var local = document.getElementById('mySelfVideo');
            let stopData = {
                "cmd": "CMD+STOP",
                "url": this.$CONFIG.STATIC_LIVE + "/" + this.urlTimeStamp,
                "port": "10"
            }
            if (local) {
                local.srcObject = null;
                local.load();
            }
            await this.requestPublicFn("gb28181Intercom", stopData);
        },

        // 请求公共方法
        requestPublicFn(API, data) {
            return new Promise( (res, rej) => {
                this.$API.cameraJump[API].post(this.receiveData.device_id, data).then( data => {
                    res(data);
                }).catch( err => {
                    messageAll.errorOpen(this.$t('cameraSetting.RequestError'))
                    rej(err);
                })
            })
        },
        
        async playVideo(deviceId, status) {
            let data = JSON.stringify({ cmd: "CMD+" + status })
            let res = await this.$API.cameraJump.playGB28181Live.post(deviceId, data);
            if (status === "STOP") return false;
            if (!res.data || res.data.code !== 200) {
                messageAll.warningOpen("获取播放地址失败");
                return
            }
            let hrefs = res.data.data;
            if (document.location.protocol === "http:") {
                hrefs = hrefs.ws_flv;
            } else {
                hrefs = hrefs.wss_flv;
            }
            return hrefs;
        },

        // 一次播放多个
        playMult(urlArr) { 
            console.log("urlArr", urlArr)
            for (let i = 0; i < urlArr.length; i++) {
                if (!urlArr[i]) continue;

                this.$refs['player'+ i][i].play(urlArr[i].url);
            }
           
        },
        // 获取多个设备播放链接
        getUrls() { 

        }
    },
    computed: {
        mqttData() {
            return this.$store.state.mqttData;
        }
    }
}
</script>

<style lang="scss" scoped>
.alarmDialog{
    width: 100%;
    height: 99%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // align-items: baseline;
    .window{
        // width: calc(50% - 0.5px);
        background-color: black;
        margin-top: 1px;
        position: relative;

    }
    .alarm-info{
        background-color: rgba(255, 255, 255, .85);
        padding: 10px;
        display: inline-block;
        border-radius: 5px;
        position: absolute;
        left: 3px;
        top: 3px;
        z-index: 5;
        .info-value{
            line-height: 20px;
        }
    }
    
    .receiveBtn {
        z-index: 5;
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
    .video-box{
        width: 100%;
        height: 100%;
    }
}
::v-deep .el-dialog{
    height: 92%;
    margin: 0 auto !important;
    margin-top: 40px !important; 
    overflow: hidden;
    border-radius: 5px;
}
::v-deep .el-dialog__header{
    padding: 0;
}
::v-deep .el-dialog__body{
    padding: 16px;
    height: calc(100% - 41px);
    box-sizing: border-box;
}
.alarm-title{
    padding: 10px;
    box-sizing: border-box;
    background-color: #327dec;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content-box{
    width: 100%;
    height: 100%;
    display: flex;
    .left-player{
        width: 70%;
        height: 100%;
        background-color: black;
        color: #fff;
        position: relative;
        .title {
            z-index: 5;
            position: absolute;
            width: 100px;
            height: 40px;
            top: calc(50% - 20px);
            left: calc(50% - 50px);
            text-align: center;
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
        }
        .refreshBtn{
            z-index: 5;
            position: absolute;
            right: 20px;
            bottom: 10px;
            text-align: center;
        }
    }
    .right-handle{
        width: 30%;
        height: 100%;

    }
}
.right-data-box {
    width: 100%;
    padding: 10px;
    // margin-top: 36px;
    box-sizing: border-box;

    .data-title {
        width: 100%;
        background: linear-gradient(to right, #78dbf9, #fff);
        padding: 8px;
        box-sizing: border-box;
        border-radius: 6px;
        color: #555;
        font-weight: bold;
    }

    .device-info-box {
        list-style: none;

        li {
            display: flex;
            font-size: 14px;
            margin-bottom: 5px;
            align-items: center;

            div {
                &:first-child {
                    font-weight: bold;
                    width: 30%
                }
            }
        }
    }

    .device-title {
        width: 100%;
        background: linear-gradient(to right, #78dbf9, #fff);
        font-size: 16px;
        border-radius: 6px;
        color: #555;
        padding: 3px 3px 3px 8px;
        box-sizing: border-box;
        font-weight: bold;
    }


    .alarm-cause-box {
        .alarm-case-radio {
            padding-top: 10px;
            display: flex;
            // flex-wrap: wrap;
            align-items: center;

            .radio-box{
                margin-right: 10px; 
                &:first-child {
                    margin-right: 0;
                }
            } 
        }
    }
}
</style>