// getMediaUrl(url) {
//     return `${config.STATIC_URL}` + url

import { STATIC_URL } from "@/config";

// },
export const pcm = {
    
    pcmData: null,
    audioContext: null,
    audioSource: null,
    isPlayingPcm: false,
    async loadPcmData(url) {
        try {
            const response = await fetch(url)
            this.pcmData = await response.arrayBuffer()
        } catch (error) {
            console.log(error)
            messageAll.errorOpen("音频加载失败！");
        }
    },
    createAudioContext() {
        const AudioContext = window.AudioContext || window.webkitAudioContext
        this.audioContext = new AudioContext()
    },
    playPcm() {
        if (!this.pcmData) return
        const audioBuffer = this.audioContext.createBuffer(
            1,                 // 单声道 
            this.pcmData.byteLength / 2, // 16位 = 2字节每样本 
            16000              // 采样率 
        )
        // 将PCM数据写入缓冲区 
        const channelData = audioBuffer.getChannelData(0)
        const view = new DataView(this.pcmData)

        for (let i = 0; i < channelData.length; i++) {
            const int16 = view.getInt16(i * 2, true)
            channelData[i] = int16 / 32768.0 // 转换为-1到1的浮点数 
        }
        // 创建音频节点并播放 
        this.audioSource = this.audioContext.createBufferSource()
        this.audioSource.buffer = audioBuffer
        this.audioSource.connect(this.audioContext.destination)
        this.audioSource.start(0)

        // this.audioSource.onended  = () => {
        //     this.isPlayingPcm = false
        //     successOpen("播放结束")
        //     this.audioSource.stop() 
        //     this.audioSource.disconnect() 
        //     this.audioSource  = null 
        // }
    },
    async beginPlay(row) {
        this.stopPlay()

        if (!this.audioContext) {
            this.createAudioContext()
        }

        await this.loadPcmData(`${STATIC_URL}${row.pcm}`)

        this.playPcm()
    },
    stopPlay() {
        if (this.audioSource) {
            this.audioSource.stop()
            this.audioSource.disconnect()
            this.audioSource = null
        }
    }
}