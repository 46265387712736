<template>
    <div id="app">
        <suspension @closeMp3="closeMp3" v-if="showSuspension"/>
        <router-view v-if="routerAlive" />
        <!-- <fireTest></fireTest> -->
        <div class="AppDialog">
            <el-dialog :title="dialogtitle" :visible.sync="dialogVisible" v-if="dialogVisible" :append-to-body='true'
                :modal-append-to-body='false' width="1150px" custom-class="center-dialog">
                <div class="AppDialogBox" style="display: flex;justify-content: center;">
                    <planPicture v-if="dialogTabShow == 'plan'" :AxiosURL="getURL(AxiosURL)" :key="planShow"
                        ref="planPicture" :position="IMGposition"></planPicture>
                    <videoCon v-if="false" :url="videoUrl" style="width:1000px;height: 235px;"></videoCon>
                    <alarmDetail v-if="dialogTabShow == 'alarmNews'" ref="alarmDetail" :alarm_data="alarm_data"
                        :mapPosition="mapPosition" :allAlarmData="allAlarmData" @clearAlarm="clearAlarm"></alarmDetail>
                    <videoList v-if="dialogTabShow == 'video'" :alarmNews="alarmData"></videoList>
                </div>
            </el-dialog>
        </div>


        <div class="alarmWindow" >
            <!-- 报警弹窗 -->
            <el-dialog :visible.sync="alarmDialog" width="1354px" :show-close="false" :close-on-click-modal="false" destroy-on-close>
                <div class="alarm-title" slot="title">
                    <div>告警信息</div>
                    <div @click="alarmDialog = false"><i class="el-icon-close" style="cursor: pointer;"></i></div>
                </div>  
                <div style="height: 100%;">
                    
                    <alarmCauseCom 
                        v-if="alarmDialog"
                        ref="alarmCauseCom"
                        :key="alarmIndex"
                        :alarmArr="alarmArr"
                        :alarmWindowIndex="alarmWindowIndex"
                        @closeAudio="closeMp3"
                        @receiveAlarm="alarmDialog = false"
                        @handleAlarm="handleAlarm"
                        @close="alarmDialog = false"
                    />
                        <!-- @receiveAlarm="receiveAlarm" -->
                </div> 
            </el-dialog> 
        </div>
        
        <video id='video1' controls autoplay v-show="false"></video>
        <video id='selfVideo1' controls autoplay v-show="false"></video>
    </div>
</template>
<script>
import planPicture from "@/views/NewestHomePage/AlarmPlatform/list/planPicture.vue"
import videoCon from "@/components/Public/video.vue"
import alarmDetail from "@/components/Public/App/alarmDetails.vue"
import videoList from "@/components/Public/videoList/hwVideo.vue"
import messageAll from "./public_Js/messageAll"
import suspension from "@/components/Public/suspension.vue"
import alarmCauseCom from "@/components/AppAlarmPop.vue";
// ./components/mqttAlarm.vue
// import mqtt from 'mqtt'
export default {
    components: {
        // fireTest
        planPicture,
        videoCon,
        alarmDetail,
        videoList,
        alarmCauseCom,
        suspension
    },
    provide() {
        return {
            reload: this.reload
        }
    },
    data() {
        const noSpaces = (rule, value, callback) => {
            if (/\s/.test(value)) {
                callback(new Error(this.$t("basic.all.AlarmPlatform_noSpacing")));
            } else {
                callback();
            }
        };
        return {
            alarmDialog: false,
            alarmIndex: 0,
            alarmArr: [null],
            alarmWindowIndex: 0,
            deviceInfo: [
                {title: "设备编码: ", type: "device_number" },
                {title: "设备型号: ", type: "device_model" },
                {title: "设备类型: ", type: "device_type" },
                {title: "所属单位: ", type: "entity_name" },
            ],
            alarmDeviceId: 0,
            urlTimeStamp: Date.now(),
            
            rules: {
                cause: [{ required: true, message: '请选择处理方式', trigger: 'blur' }],
                remarks: [
                    { required: true, message: '请输入备注', trigger: 'blur' },
                    { validator: noSpaces, trigger: 'blur' }
                ]
            },
            connection: {
                host: 'mqtt.tranthing.com',
                // host: '192.168.3.176',
                port: 9001,
                path: '/'
            },
            userName: "",
            mtopic: "device/alarm/",
            alarmMsg: "",
            dialogVisible: false,
            // 平面图url
            AxiosURL: '',
            IMGposition: '',
            planShow: 0,
            videoUrl: '',
            dialogTabShow: '',
            alarmData: {},
            mapPosition: {},

            dialogtitle: '',
            notifications: {},

            newHight: '',
            newVideoWidth: '',
            timeDeatail: null,
            videoMsg: null,
            videoUrlBlob: null,
            audioLoop: null,
            alarmId: null,
            // 无感刷新
            routerAlive: true,
            // mqtt是否首次重连
            isFirstReconnect: true,
            isFirstError: true,
            blackAlarmDevice: [], // 报警黑名单
            // mqtt断开测试
            mqttLossTime: 0,
            isOpenTips: false,
            worker: null, 
            mqttText: null,
            showSuspension: true,
            mqttData: null,
            policeState: false,
            audio: null,
        }
    },
    watch: {
        getAlarmStatusByStore(nv){
            if (nv === false) {
                if (this.audioLoop) clearTimeout(this.audioLoop)
                window.speechSynthesis.cancel();
                this.videoMsg = null;
                this.audioLoop = null;
                this.mqttData = null;
                this.policeState = false;
            }
        },
        $route(r) {
            if (r.path === "/login" ) {
                this.showSuspension = false;
                this.$notify.closeAll();
                window.speechSynthesis.cancel();
                this.mqttData = null;
                if (this.audioLoop) clearTimeout(this.audioLoop)
                if( !this.$store.state.postMessage ) return;
                this.$store.state.postMessage({
                    cancelSubScript: true,
                })
            } else {
                this.showSuspension = true;
            }
        },
        mqttData() {
            // if (this.videoMsg || this.audioLoop) return
            if(!this.mqttData) return;
            if (this.audioLoop) clearTimeout(this.audioLoop)
            window.speechSynthesis.cancel();
            this.videoMsg = null;
            this.audioLoop = null;

            this.policeState = true;
            let alarmType = localStorage.getItem("aduio");
            if (alarmType == 'addr') {
                if (this.audioLoop) clearTimeout(this.audioLoop)
                this.playVoice(this.mqttText);
            } else if (alarmType === "mp3" ) {
                window.speechSynthesis.cancel();
                this.getVideoUrl()
            }
        },
        alarmDialog(nv) {
            if (!nv) this.closeRTC();
        }
    },
    mounted() {
        let loginHref = window.location.href;

        if (loginHref.search("token=") != -1 || (loginHref.search("/login") != -1)) {
            this.showSuspension = false;
            if ( this.worker.postMessage) {
                this.worker.postMessage({
                    cancelSubScript: true,
                })
            }
            if (loginHref.search("/token=") != -1) {
                let token = loginHref.substring(loginHref.indexOf("token=") + "token=".length);
                if (!token) return;
                this.$store.commit("setToken", token);
                this.getUserInfo()
            }
        } else {
            if(this.worker.postMessage) {
                this.worker.postMessage({
                    // userName: JSON.parse(localStorage.getItem("AllTitle")).login_name
                    userName: localStorage.getItem("loginName")
                })
            }
        }
        localStorage.getItem('aduio') ? '' : localStorage.setItem('aduio', 'mp3')
        // talbe重新赋值高度
        this.getAlarmReason();
        this.newHeight()
        this.newWidth = window.devicePixelRatio * 1064 + 'px'
        this.newVideoWidth = window.devicePixelRatio * 600 + 'px'
        window.addEventListener("resize", this.getResize)
        window.document.documentElement.setAttribute("data-theme", this.$CONFIG.THEME);
        window.addEventListener("beforeunload", e => {
            this.unloadFn(e);
        });
    }, 
    // 销毁函数
    beforeDestroy() {
        // 告诉worker销毁mqtt
        window.removeEventListener("resize", this.getResize);
        window.removeEventListener("beforeunload", e => {
            this.unloadFn(e);
        });
        if (!this.$store.state.postMessage) return;
        this.$store.state.postMessage({
            cancelSubScript: true,
        })
    },
    async created() {  
        if (!window.Worker) { 
            throw new Error('当前浏览器不支持多线程')
        }
        
        this.worker = new Worker("../worker/web.worker.js"); 
        // this.worker.postMessage({
        //     userName: localStorage.getItem("loginName")
        // })
        // 监听子线程信息
        this.worker.addEventListener('message', this.messageHandle)

        this.$store.commit("setWorker", this.worker);
    },
    destroyed() {
        
        // 记得销毁线程
        this.worker.removeEventListener('message', this.messageHandle)
        this.worker.terminate();
    },
    methods: {
        handleAlarm() {
            messageAll.successOpen(this.$t("basic.alarmPlatform.mess_handleAlarmSuccess"))
            // this.alarmDialog = false;
            // this.notifications[this.mqttData.alarm_id + ""].close();
            this.closeRTC();
            this.$store.commit('mqttIsHandle');
        },
        async getUserInfo() {
            let title = await this.$API.account_basic.loginNews.get({
                language: localStorage.getItem('theLanguage')
            })
            let loginName = title.data.login_name;
            this.$store.state.worker.postMessage({
                userName: loginName
            })
            this.$store.commit("setLoginName", loginName);
            this.$store.commit("setUserRoleId", title.data.role_id);
            // 先把 路由 存储好后续跳转会用到
            this.$store.commit('setMenu', title.data.menu);
            this.$store.state.menu = title.data.menu;
            if (title.data.platform_title == '') {
                title.data.platform_title = this.$t("basic.newLogin.platformTitle") //"安防后台管理"
            }
            if (title.data.entity_name == '') {
                title.data.entity_name = this.$t("basic.newLogin.title") //"智慧安防云平台"
            }
            this.$store.commit('getAllTitle', title.data)
        },
        unloadFn() {
            if (this.$route.path === "/login") return;
            let token = localStorage.getItem("token");
            if (!token) {
                this.$router.push({ name: "login" })
                if (!this.$store.state.postMessage) return;
                this.worker.postMessage({
                    cancelSubScript: true,
                })
            }
        },
        closeMp3(val) {
            if (!this.policeState) return;
            if (this.audioLoop) clearInterval(this.audioLoop);
            if (this.videoMsg) window.speechSynthesis.cancel()
            if (val === "closeMp3") {
                this.videoMsg = null
                this.audioLoop = null;
            } else if (val === "addr") {
                this.playVoice(this.mqttText)
            } else if( val === "mp3" ) {
                this.getVideoUrl();
            }
        },
        
        // 消息处理
        async messageHandle(e) {
            if (e.data.log) {
                if( e.data.log != "success" ){
                    this.connectState = false;
                } else {
                    this.connectState = true;
                }
                this.$store.commit("mqttConnect", this.connectState);
                return;
            }
            if(!e.data.mqttInfo) return
            let json_obj = e.data.mqttInfo;
            this.mqttData = json_obj;
            this.$store.commit("setMattData", json_obj);
            this.alarmArr[0] = json_obj;
            if (this.$route.path == "/login" || this.$route.path == "/AlarmPlatform" || this.$route.path == "/FiremAlarmSystem" || this.$route.path == "/GasSafetySystem") {
                return
            }
            if (this.$route.path == "/videoSystem" || this.$route.path == "/MonitoringSubPlatforms" || this.$route.path == "/mqttVideo") {
                if (json_obj.device_type.search('摄像头') != -1) {
                    return;
                }
            }
            let audioType = localStorage.getItem("aduio");
            if (audioType === "addr") {
                this.playVoice(this.mqttText)
            }
            // let myTopic = this.mtopic + this.userName
            this.showNotify(json_obj);
        },
        openMqttCloseTips() {
            if (!this.isOpenTips) {
                this.isOpenTips = true
            } else {
                return
            }
            this.$alert('平台和服务器连接断开，点击确定刷新页面重连', '平台连接异常', {
                confirmButtonText: '确定',
                callback: action => {
                    this.isOpenTips = false
                    if (action == "confirm") {
                        if (this.mqttLossTime == 0) {
                            messageAll.successOpen("已经重连成功，不刷新页面")
                        } else {
                            location.reload()
                        }
                    } else {
                        if (this.mqttLossTime == 0) {
                            messageAll.successOpen("已经重连成功，不刷新页面")
                        } else {
                            location.reload()
                        }
                    }
                }
            });
        },
        // 无感刷新
        async reload() {
            await this.getALltitle()
            this.routerAlive = false
            this.$nextTick(() => {
                this.routerAlive = true
            })
        },
        getVideoUrl() {
            if (this.audio) this.audio.pause();
            this.audio = new Audio()
            this.audio.src = 'https://web.tranthing.com/api/static/work-file/alarm.mp3'
            this.audio.play()
            this.audioLoop = setInterval(() => {
                this.audio.play()
            }, 1000)
        },

        async playVoice(text) {
            this.videoMsg = new SpeechSynthesisUtterance();
            let language = localStorage.getItem('theLanguage')
            if (language == "zh") {
                this.$store.commit('changeLanguage', language)
                this.videoMsg.lang = "zh-CN";
                this.videoMsg.text = "位于" + text + '的设备正在报警,请及时确认！'
            }
            if (language == "en") {
                this.videoMsg.lang = "en-US";
                this.videoMsg.text = 'The' + text + ' address is currently under alarm. Please check it promptly'
            }
            if (language == "ko") {
                this.videoMsg.lang = "ko-KR";
                this.videoMsg.text = '위치' + text + '경보음이 울리니 즉시 확인하시기 바랍니다'
            }
            this.videoMsg.onend = () => {
                this.playVoice(text)
            }
            window.speechSynthesis.speak(this.videoMsg);
        },
        getResize() {
            this.newHeight()
            this.changeDetail()
            this.changeTime()
        },
        newHeight() {
            let bodyHight = window.innerHeight
            let height = bodyHight - 303 + 'px'
            let noExHeight = bodyHight - 268 + 'px'
            this.$store.commit('changeNoExTableHeight', noExHeight)
            this.$store.commit('changeTableHeight', height)
        },
        // 改变宽度
        changeDetail() {
            let width = window.innerWidth
            if (this.timeDeatail) {
                clearTimeout(this.timeDeatail)
            }
            if (width > (1300 * window.devicePixelRatio) && width < (1700 * window.devicePixelRatio)) {
                this.timeDeatail = setTimeout(() => {
                    this.$store.commit('changeDetailWidth', '70%')
                }, 200);
            }
            if (width < (1300 * window.devicePixelRatio) && width > (1000 * window.devicePixelRatio)) {
                this.timeDeatail = setTimeout(() => {
                    this.$store.commit('changeDetailWidth', '80%')
                }, 200);
            }
            if (width < (1000 * window.devicePixelRatio)) {
                this.timeDeatail = setTimeout(() => {
                    this.$store.commit('changeDetailWidth', '90%')
                }, 200);
            }
            if (width > (1700 * window.devicePixelRatio)) {
                this.timeDeatail = setTimeout(() => {
                    this.$store.commit('changeDetailWidth', '60%')
                }, 200);
            }
        },

        async getALltitle() {
            let title = await this.$API.account_basic.loginNews.get()
            if (title.data.platform_title == '') {
                title.data.platform_title = this.$t("basic.menubar.backstageManage")
            }
            if (title.data.entity_name == '') {
                title.data.entity_name = this.$t("basic.newLogin.title")
            }

            if (title.data.entityLogo == '') {     //todo
                title.data.entityLogo = ''
            }
            this.$store.commit('getAllTitle', title.data)
        },
        //侧边弹框
        async showNotify(msg) {
            let _this = this;
            /*
            // 屏蔽设备获取屏蔽设备列表, 通过ID匹配进行拦截弹窗
            let data = null;
            let flag = false;
            data = await this.$API.device.getBlackAlarmDeviceList.get();
            if (data.code == 200 ) { // 请求成功
              if (data.data.count != 0) { // count 为0时表示没有被屏蔽的设备, 不执行下面的代码
                for (let i = 0; i < data.data.data.length; i++) {
                  if ( data.data.data[i].id == msg.device_id ) {
                    flag = true;
                    return;
                  }
                } 
              }
            }
            // 经过以上的设备id匹配, 如果匹配到黑名单设备, 则不弹窗报警, 否则继续往下执行
            if(flag) return;
            */
            const h = this.$createElement
            let notify = this.$notify({
                onClose: function () {
                    if (_this.alarmId == msg.alarm_id) {
                        clearTimeout(_this.audioLoop);
                        _this.audioLoop = null
                        if (_this.videoMsg) window.speechSynthesis.cancel()
                        _this.videoMsg = null
                    }
                    _this.msgNotify = ''
                    _this.policeState = false;
                },
                duration: 0,
                dangerouslyUseHTMLString: true,
                customClass: 'notify-msg',
                message: h(
                    'div',
                    {
                        class: 'notify-msg-box',
                    },
                    [
                        h('div', { class: 'notify-msg-top' }, [
                            h('div', { class: 'notify-msg-icon' }, [
                                h('div', { class: 'image', attrs: { id: msg.alarm_id }, on: { click: () => { this.test(msg.alarm_id) } } }),
                            ]),
                            h('div', { class: 'notify-msg-top-right' }, [
                                h('div', { class: 'notify-msg-title' }, this.$t("basic.all.mqtt_deviceAlarm")),
                                h('div', { class: 'notify-msg-context' }, this.$t("basic.public.deviceCoding") + '：' + msg.device_number),
                                h('div', { class: 'notify-msg-context' }, this.$t("basic.public.typeTitle") + '：' + msg.device_type),
                                h('div', { class: 'notify-msg-context' }, this.$t('basic.pushRecords.AliSmsTime') + '：' + msg.device_data.time),
                                h('div', { class: 'notify-msg-context' }, this.$t('basic.digitalPage.middenMap_owenr') + '：' + msg.owner.contactName + ' ' + msg.owner.contactrPhone),
                                h('div', { class: 'notify-msg-context' }, this.$t("basic.deviceList.bind_installSite") + '：' + msg.location),
                            ]),
                        ]),

                        h('div', { class: 'notify-msg-bottom' }, [
                            h(
                                'button',
                                //  样式修改                    方法赋值
                                { class: 'notify-msg-btn', on: { click: () => { this.openAlarm(msg.alarm_data, msg) } } },
                                this.$t("basic.all.mqtt_deviceAlarmDetail"),
                            ),
                            h(
                                'button',
                                { class: 'notify-msg-btn', on: { click: () => { this.openVideo(msg) } } },
                                this.$t("basic.all.mqtt_checkVideo")
                            ),
                            h(
                                'button',
                                { class: 'notify-msg-btn', on: { click: () => { this.openPicture(msg.pointPhoto, msg.local_coord) } } },
                                this.$t("basic.alarmPlatform.planeImg")
                            ),
                            h(
                                'button',
                                { class: 'notify-msg-btn-last', on: { click: () => { this.open3D() } } },
                                this.$t("basic.all.mqtt_model")
                            ),
                        ]),

                    ]
                ),
                offset: 50, // 向下偏移100
            })

            this.notifications[msg.alarm_id + ""] = notify;
            this.mqttText = msg.location;
            this.alarmId = msg.alarm_id;
            
        },
        // 打开平面图
        openPicture(msg, point) {
            if (!msg) {
                messageAll.warningOpen(this.$t("basic.alarmPlatform.message_plangImgLose"))
                return
            }
            this.dialogtitle = this.$t("basic.alarmPlatform.planeImg")
            this.planShow += 1
            this.dialogTabShow = 'plan'
            this.dialogVisible = true
            this.IMGposition = point
            this.AxiosURL = msg
            this.$nextTick(() => {
                this.$refs.planPicture.initMap()
            })

        },

        // 打开视屏
        async openVideo(msg) {
            this.alarmDeviceId = msg.device_id;

            // 非摄像头设备
            if (msg.device_type.search("摄像头") === -1) {
                if (msg.CameraUrl.length === 0) {
                    messageAll.warningOpen(this.$t('basic.alarmPlatform.message_noRelevanc'))
                } else {
                    this.alarmArr = [];
                    for (let i = 0; i < msg.CameraUrl.length; i++) {
                        if (i >= 3) break;
                        let obj = JSON.parse(JSON.stringify(msg));
                        let id =  msg.CameraUrl[i]
                        obj.device_id = id;
                        let res = await this.$API.device.detail.get(id);
                        if (res.code == 200) {
                            let data = res.data.device_data;
                            obj.device_number = data.device_id;
                            obj.device_type = data.device_type_name;
                            obj.time = data.activity_time;
                        }
                        this.alarmArr[i] = obj;
                    }
                    this.alarmDialog = true;
                }
                return
            }
            // 以下为摄像头设备
            if (msg.CameraUrl.length > 0) {
                this.alarmArr = [msg, null, null, null];

                for (let i = 0; i < msg.CameraUrl.length; i++) {
                    if (i >= 3) break;
                    let obj = JSON.parse(JSON.stringify(msg));
                    let id = msg.CameraUrl[i];
                    obj.device_id = id;
                    let res = await this.$API.device.detail.get(id);
                    if (res.code == 200) {
                        let data = res.data.device_data;
                        obj.device_number = data.device_id;
                        obj.device_type = data.device_type_name;
                        obj.time = data.activity_time;
                    }
                    this.alarmArr[i + 1] = obj;
                }
                this.alarmDialog = true;
                return;
            }
            // 没有关联别的摄像头设备
            this.alarmArr = [msg];
            this.alarmDialog = true;
        },
        
        // 报警信息
        openAlarm(msg, val) {
            this.dialogtitle = this.$t("basic.all.mqtt_deviceAlarmDetail")
            this.mapPosition['lat'] = val.lat
            this.mapPosition['lon'] = val.lng
            this.dialogTabShow = 'alarmNews'
            this.dialogVisible = true
            this.alarm_data = msg
            this.allAlarmData = val

            // this.$nextTick(()=>{
            //   this.$refs.alarmDetail.disposeAlarm.cause = '',
            //   this.$refs.alarmDetail.disposeAlarm.remarks = ''
            // })
        },

        // 处理报警
        clearAlarm(devcieId, alarmId) {
            // 关闭弹框
            this.dialogTabShow = "";

            this.notifications[alarmId + ""].close();
            this.dialogVisible = false
            clearTimeout(this.audioLoop)
            this.audioLoop = null
            if (this.videoMsg) window.speechSynthesis.cancel()
            // if(this.alarmId == alarmId){

            // }
        },
        test(val) {
            let el = document.getElementById(val)
            this.policeState = false;
            el.className = 'imageClose'
            
            if (this.audioLoop) clearInterval(this.audioLoop);
            if (this.videoMsg) window.speechSynthesis.cancel();
            this.videoMsg = null;
            this.audioLoop = null;
            this.audio = null;

        },
        // 3D模型
        open3D() {
            messageAll.warningOpen("此设备暂无3D模型!")
        },

        // 拼接服务器URL
        getURL(url) {
            return this.$TOOL.getMediaUrl(url)
        },
        // 播放视频
        async playVideo(deviceId, status) {
            let data = JSON.stringify({ cmd: "CMD+" + status })
            let res = await this.$API.cameraJump.playGB28181Live.post(deviceId, data);
            if (status === "STOP") return false;
            if (!res.data || res.data.code !== 200) {
                messageAll.warningOpen("获取播放地址失败");
                return
            }
            let hrefs = res.data.data;
            
            if (document.location.protocol === "http:") {
                hrefs = hrefs.ws_flv;
            } else {
                hrefs = hrefs.wss_flv;
            }
            return hrefs;
        },
        async getAlarmReason() {
            await this.$API.alarm.alarmReason.get({});
        },
       
        // 麦克风事件
        async microphoneFn() {
            this.urlTimeStamp = new Date().getTime();
            this.start_play();
            if( this.rtcPlayer ) {
                let startData = {
                    "cmd": "CMD+START",
                    "url": this.$CONFIG.STATIC_LIVE_URL + this.urlTimeStamp,
                }
                // 开始对讲
                await this.requestPublicFn("gb28181Intercom", startData);
                // this.braadcast = false;
            } else {
                messageAll.warningOpen(this.$t('cameraSetting.notImplemented'))
            }
        },

        changeTime() {
            let Time = document.getElementsByClassName('has-time')
            if (Time.length > 0) {
                let i = 0
                for (i = 0; i < Time.length; i++) {
                    Time[i].style.display = 'none'
                }
            }
        },
        // 开始对讲
        start_play(){
            let url = new URL(`${this.$CONFIG.STATIC_LIVE}&stream=${this.urlTimeStamp}&type=push`);
            this.rtcPlayer = new ZLMRTCClient.Endpoint({
                element: document.getElementById('video1'),// video 标签
                debug: true,// 是否打印日志
                zlmsdpUrl: url,//流地址
                simulcast: false,
                useCamera: false,
                audioEnable: true,
                videoEnable: false,
                recvOnly: false,
                resolution: { w: 1920, h: 1080 },
                usedatachannel: false,
            });

            // this.rtcPlayer.on(ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR, function (e) {
            //     // ICE 协商出错
            //     console.log('ICE 协商出错', e);
            // });

            // this.rtcPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS, function (e) {
            //     //获取到了远端流，可以播放
            //     console.log('播放成功', e.streams);
            // });

            // this.rtcPlayer.on(ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED, function (e) {
            //     // offer anwser 交换失败
            //     console.log('offer anwser 交换失败', e);
            //     // stop();
            // });

            // // this.rtcPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM,function(s) {// 获取到了本地流
            // //     document.getElementById('selfVideo').srcObject=s;
            // //     document.getElementById('selfVideo').muted = true;
            // // });

            // this.rtcPlayer.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED,function(s) {// 获取本地流失败

            //     console.log('获取本地流失败', s)
            // });
            
            // RTC 状态变化 ,详情参考 https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/connectionState
            // this.rtcPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_CONNECTION_STATE_CHANGE,function(state) {
            //     console.log('当前状态==>',state)
            // });

            // this.rtcPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_OPEN,function(event) {
            //     console.log('rtc datachannel 打开 :',event)
            // });

            // this.rtcPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_MSG,function(event) {
            //     console.log('rtc datachannel 消息 :',event.data)
            //     document.getElementById('msgrecv').value = event.data
            // });
            // this.rtcPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_ERR,function(event) {
            //     console.log('rtc datachannel 错误 :',event)
            // });
            // this.rtcPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_CLOSE,function(event) {
            //     console.log('rtc datachannel 关闭 :',event)
            // });
        },
        
        // 关闭对讲功能
        async closeRTC() {
            if (!this.rtcPlayer) return 
            this.rtcPlayer.close();
            this.rtcPlayer.closeDataChannel();
            this.rtcPlayer = null;
            var remote = document.getElementById('video1');
            if(remote) {
                remote.srcObject = null;
                remote.load();
            }
            var local = document.getElementById('selfVideo1');
            let stopData = {
                "cmd": "CMD+STOP",
                "url": this.$CONFIG.STATIC_LIVE + "/" + this.urlTimeStamp,
                "port": "10"
            }
            if (local) {
                local.srcObject = null;
                local.load();
            }
            await this.requestPublicFn("gb28181Intercom", stopData);
        },
        // 请求公共方法
        requestPublicFn(API, data) {
            return new Promise( (res, rej) => {
                this.$API.cameraJump[API].post(this.alarmDeviceId, data).then( data => {
                    res(data);
                }).catch( err => {
                    messageAll.errorOpen(this.$t('cameraSetting.RequestError'))
                    rej(err);
                })
            })
        },

    },
    computed: {
        getAlarmStatusByStore() {
            return this.$store.state.alarmType
        }
    }
}
</script>


<style lang="scss">
@import "@/assets/scss/theme/handle";
@import "@/assets/scss/publicStyle/index.scss";

#app {
    @include background_color("background_color3");
    overflow: hidden;
    // display: flex;
}

.textPoint{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
// 去除滚动条
// ::-webkit-scrollbar {
//         width: 0px;
//         height: 10px;
//         background-color:rgb(240,242,245);
//       }

// 滚动条  
::-webkit-scrollbar {
    width: 8px;
    background-color: rgb(240, 242, 245);
    border-radius: 8px;
}

// 滚动条滑块样式
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgb(97, 97, 97);
}

/**
修改notify样式
 */
.notify-msg {
    margin: 0;
    padding: 0;
    width: 440px;
    z-index: 1000 !important;

    .el-notification__group {
        margin: 0;
        padding: 0;
    }
}



</style>

<style lang="scss" scoped>

body {
    overflow: hidden;
}

.notify-msg-box {
    margin: 0;
    padding: 0;

    .notify-msg-top {
        display: flex;
        padding: 20px 20px 20px 20px;

        .notify-msg-icon {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .image {
                width: 55px;
                height: 70px;
                cursor: pointer;
                background-image: url('assets/img/alarm_on.gif');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                -moz-background-size: 100% 100%;
            }

            .imageClose {
                width: 55px;
                height: 70px;
                cursor: pointer;
                background-image: url('assets/img/ea94042347aebe95da6fec99d6f8b99.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                -moz-background-size: 100% 100%;
            }
        }

        .notify-msg-top-right {
            padding-left: 30px;

            .notify-msg-title {
                font-weight: 700;
                font-size: 20px;
                color: #f56c6c;
            }

            .notify-msg-context {
                padding-top: 8px;
                color: #666666;
                font-size: 14px;
            }
        }
    }

    .notify-msg-bottom {
        display: flex;
        height: 50px;
        width: 440px;
        justify-content: center;
        align-items: center;
        display: flex;
        background-color: #f1f1f1;

        .notify-msg-btn {
            background: #409eff;
            border: none;
            color: #ffffff;
            width: 80px;
            height: 35px;
            border-radius: 5px;
            cursor: pointer;
            margin-right: 20px;
        }

        .notify-msg-btn-last {
            background: #409eff;
            border: none;
            color: #ffffff;
            width: 80px;
            height: 35px;
            border-radius: 5px;
            cursor: pointer;
        }

        .notify-msg-btn:hover,
        .notify-msg-btn-last:hover {
            background: #8cc5ff;
        }
    }

    .notify-bottom {
        width: 440px;
        display: flex;
        flex-direction: row-reverse;

        .el-button {
            margin-bottom: 20px;
            margin-right: 30px;
            color: white;
            font-weight: 700;
            width: 100px;
            padding-top: 12px;
            padding-bottom: 12px;
            font-size: 14px;
            background-color: #409eff;
        }
    }

}

.AppDialogBox {
    width: 100%;
    height: 70vh;
}
.alarm-title{
    padding: 10px;
    box-sizing: border-box;
    background-color: #327dec;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content-box{
    width: 100%;
    display: flex;
    height: 600px;
    .left-player{
        width: 70%;
        height: 100%;
        background-color: black;
        color: #fff;
        position: relative;
        .title {
            z-index: 5;
            position: absolute;
            width: 100px;
            height: 40px;
            top: calc(50% - 20px);
            left: calc(50% - 50px);
            text-align: center;
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
        }
        .refreshBtn{
            z-index: 5;
            position: absolute;
            right: 20px;
            bottom: 10px;
            text-align: center;
        }
    }
    .right-handle{
        width: 30%;
        height: 100%;

    }
}

.right-data-box {
    width: 100%;
    padding: 10px;
    // margin-top: 36px;
    box-sizing: border-box;

    .data-title {
        width: 100%;
        background: linear-gradient(to right, #78dbf9, #fff);
        padding: 8px;
        box-sizing: border-box;
        border-radius: 6px;
        color: #555;
        font-weight: bold;
    }

    .device-info-box {
        list-style: none;

        li {
            display: flex;
            font-size: 14px;
            margin-bottom: 5px;
            align-items: center;

            div {
                &:first-child {
                    font-weight: bold;
                    width: 30%
                }
            }
        }
    }

    .device-title {
        width: 100%;
        background: linear-gradient(to right, #78dbf9, #fff);
        font-size: 16px;
        border-radius: 6px;
        color: #555;
        padding: 3px 3px 3px 8px;
        box-sizing: border-box;
        font-weight: bold;
    }


    .alarm-cause-box {
        .alarm-case-radio {
            padding-top: 10px;
            display: flex;
            // flex-wrap: wrap;
            align-items: center;

            .radio-box{
                margin-right: 10px; 
                &:first-child {
                    margin-right: 0;
                }
            } 
        }
    }
}

.alarmWindow{
    ::v-deep .el-dialog{
        height: 840px;
        overflow: hidden;
        border-radius: 5px;
    }
    ::v-deep .el-dialog__header{
        padding: 0; 
    }
    ::v-deep .el-dialog__body{
        height: 100%;
        box-sizing: border-box;
    }

    .alarm-title{
        padding: 10px;
        box-sizing: border-box;
        background-color: #327dec;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

// .AppDialog{
::v-deep .el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

::v-deep .el-dialog__header{
    padding: 10px 10px 0 10px !important;
    box-sizing: border-box;
}
::v-deep .el-dialog__body{
    padding: 10px !important;
    box-sizing: border-box;
}

</style>