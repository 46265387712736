<template>
    <div class="device-type-stats">
        <!-- 视频播放容器 -->
        <div id="video" ref="video"> </div>
    </div>
</template>

<script>
export default {
    name: "DemoPlayer",
    component: {
    },
    props: {
        url: { type: String, default: "" },
        // 播放屏幕显示个数
        isShowOperateBtns: {
            type: Number,
            default: () => {
                return 1
            }
        },
        showPTZ: {
            type: Boolean,
            default: () => {
                return true
            }
        },
        devcieID: {},
        HorizonSpeed: {},
        VerticalSpeed: {},

    },
    watch: {
        isShowOperateBtns: {
            handler: async function () {
                // this.jessibuca.destroy();
                await this.create()
                await this.play()
            }
        },
        url() {
            this.play();
        },
        deep: true,
    },
    data() {
        return {
            wasm: true,
            playing: true,
            quieting: true,
            loaded: false, // mute
            showOperateBtns: true,
            err: "",
            performance: "",
            volume: 1,
            rotate: 0,
            vod: true,
            forceNoOffscreen: true,
            buffer: 0.2,
            changeResizeModel: false,
            cent_Show: false,
            timeout: null,
            jessibuca: null
        };
    },
    
    beforeDestroy() {
        this.destroyVideo();
    },
    
    mounted() {
        this.create();
        // window.onerror = (msg) => (this.err = msg);
    },

    methods: {

        // 实例化 jessibuca 插件
        create() {
            let jessibucaDemo = null;
            jessibucaDemo = {
                container: this.$refs.video,
                videoBuffer: 0.2, // 设置最大缓冲时长，单位秒，播放器会自动消除延迟。
                videoBufferDelay: 1, // 视频缓冲区延迟
                isResize: true, // 当为true的时候：视频画面做等比缩放后,高或宽对齐canvas区域,画面不被拉伸,但有黑边。
                text: "",
                hiddenAutoPause: false,
                loadingText: this.$t("cameraSetting.loading"), //  加载过程中文案。
                debug: false, //
                debugLevel: "debug",
                useMSE: false, // 否开启MediaSource硬解码
                decoderErrorAutoWasm: false,
                useSIMD: false,
                useWCS: false, // 否开启Webcodecs硬解码
                useMThreading: true,
                showBandwidth: this.showOperateBtns, // 显示网速
                showPerformance: false,  // 显示性能
                operateBtns: {  // 配置操作按钮
                    fullscreen: this.showOperateBtns,  // fullscreen 是否显示全屏按钮
                    screenshot: this.showOperateBtns,   // screenshot 是否显示截图按钮
                    play: false,     // play 是否显示播放暂停按钮
                    audio: this.showOperateBtns,    // audio 是否显示声音按钮
                    ptz: false,  // 是否显示 上下左右控制器按钮
                    record: this.showOperateBtns,    // record 是否显示录制按钮
                    // performance:true, // 是否显示性能按钮
                    zoom: this.showOperateBtns, // 是否显示 放大缩小
                },
                forceNoOffscreen: true,     //是否不使用离屏模式（提升渲染能力）
                isNotMute: false,
                timeout: 10000,  // 设置超时时长, 单位秒
                heartTimeoutReplayUseLastFrameShow: true,
                audioEngine: "worklet",
                heartTimeout: 5,   // 设置心跳时长, 单位秒
                heartTimeoutReplay: true,
                heartTimeoutReplayTimes: -1, // 无限重复拉流
                loadingTimeout: 10, //
                loadingTImeoutReplay: true,
                loadingTImeoutReplayTimes: -1,
                ptzClickType: "mouseDownAndUp", //// 控制器类型: 点击显示
                ptzZoomShow: false, // 视频控制器按钮 左上, 右上, 右下, 左下
                ptzMoreArrowShow: false, // 视频控制器按钮 左上, 右上, 右下, 左下
                ptzApertureShow: false, // 视频控制器按钮 左上, 右上, 右下, 左下
                ptzFocusShow: false, // 视频控制器按钮 左上, 右上, 右下, 左下
                useCanvasRender: false,
                useWebGPU: true,
                controlAutoHide: true, // 控制按钮是否自动隐藏
            }
            this.jessibuca = new window.JessibucaPro(jessibucaDemo);

            // 暂停
            this.jessibuca.on("pause",  () => {
                this.playing = false;
            });

            //暂停后重新播放
            this.jessibuca.on("play", async  () => {
            });
            this.jessibuca.on(JessibucaPro.EVENTS.playFailedAndPaused, () => {
                this.destroy();
            })
            

            // this.jessibuca.on("websocketClose", async () => {
            //     if (_this.RestartNum < 3) {
            //         setTimeout(() => {
            //             // this.jessibuca.play(_this.newUrl)
            //         }, 1000)
            //     } else {
            //         return
            //     }
            // })
            // jessibuca 控制上下左右按钮
            this.jessibuca.on("ptz",  arrow => {
                // _this.jessibuca.getPTZCmd(arrow)
                this.$emit('ptz', arrow)
            });
        },
        // 播放视频
        play() {
            if (!this.url) return
            if (this.jessibuca) {
                this.jessibuca.destroy().then(() => {
                    this.create();
                    this.jessibuca.play(this.url);
                });
            } else {
                this.create();
                this.jessibuca.play(this.url);
            }
        },

        // 触发声音事件
        mute() {
            this.jessibuca.mute();
        },

        // 取消静音
        cancelMute() { this.jessibuca.cancelMute() },

        // 暂停
        pause() { this.jessibuca.pause() },

        // 设置音量
        volumeChange() { this.jessibuca.setVolume(this.volume) },

        // 旋转角度
        rotateChange() { this.jessibuca.setRotate(this.rotate)},

        // 销毁
        destroy() {
            if(!this.jessibuca) return
            this.jessibuca.destroy();
            this.jessibuca = null;
        },

        // 截图
        screenShot() {
            try {
                const imgData = this.jessibuca.screenshot("test", "png", 0.92, 'base64');
                return imgData
            } catch{
                return null
            }
        },
        close() {
            if (this.jessibuca) {
                this.jessibuca.close();
            }
        },
        recording() {
            return this.jessibuca.isRecording();
        },
        destroyVideo() {
            if( this.jessibuca ){
                this.jessibuca.destroy();
            }
            this.jessibuca = null;
            this.playing = false;
        },
       
    },

};
</script>

<style lang="scss" scoped>
.timeSlider {
    width: 99.8%;
    height: 30px;
}

.device-type-stats {
    height: 100%;
    width: 100%;
    background-color: rgba(243, 250, 250, .2);
    border-radius: 2px;
    backdrop-filter: blur(1px);
    position: relative;
}

#video {
    //  min-width: 300px;
    //  min-height: 500px;
    height: 100%;
    width: 100%;
}

.topBox {
    width: 70px;
    height: 70px;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: rgba(68, 157, 240, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    flex-wrap: wrap;
    border-radius: 15px;
    font-size: 18px;

    i {
        font-size: 45px;
    }

}
</style>
