<template>
    <div class="infoBox" style="height: 100%" ref="infoBox">
        <div class="detailHeader" ref="header">
            <!-- deviceList 更多搜索框弹出层 -->
            <span style="position: relative; margin-right: 20px">
                <el-popover 
                    placement="bottom" 
                    width="590" 
                    :append-to-body='false'
                    popper-class="popperClass"
                    trigger="click"
                    >
                    <div style="padding: 10px 15px; display: flex; flex-wrap: wrap;">
                        <div class="select-box">
                            <span class="labeltitle">{{$t("cameraImg.EventCode")}}:</span>
                            <el-select v-model="params.EventCode" :placeholder="$t('cameraImg.selectCode')" size="medium" style="width: 200px;" :popper-append-to-body="false">
                                <el-option
                                v-for="item in eventCode"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>

                        <div class="select-box">
                            <span class="labeltitle">{{$t("basic.public.eventType")}}:</span>
                            <el-select v-model="params.EventType" :placeholder="$t('basic.modelNum.model_selectEvent')" size="medium" style="width: 200px;" :popper-append-to-body="false">
                                <el-option
                                v-for="item in eventType"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>

                        <div class="select-box">
                            <span class="labeltitle">{{$t("cameraImg.TagName")}}:</span>
                            <el-select v-model="params.LabelType" :placeholder="$t('cameraImg.selectTagName')" size="medium" style="width: 200px;" :popper-append-to-body="false">
                                <el-option
                                v-for="item in labelData"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </div>  
                    </div>
                    <el-badge :value="3" class="item" slot="reference">
                        <el-button type="text" style="padding-right: 5px;">{{ $t("basic.public.moreSear") }}</el-button>
                    </el-badge>
                </el-popover>
            </span>
            
            <span style="position: relative;">
                <el-date-picker
                    v-model="time"
                    type="daterange"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    :append-to-body="false"
                    :editable="false"
                    :default-time="['00:00:00', '23:59:59']"
                    :start-placeholder='$t("basic.public.Time_start")'
                    @change="changeTime"
                    :end-placeholder='$t("basic.public.Time_end")'>
                </el-date-picker>
            </span>
            <el-button type="primary" style="margin-left: 15px;" size="medium" @click="clearmSearch">
                <i class="iconfont icon-shanchujinzhi"></i>
            </el-button>
            <el-button type="primary" style="margin-right: 15px;" size="medium" @click="searchImg">
                <i class="iconfont icon-sousuo"></i>
            </el-button>


            <el-button type="text" size="mini" style="margin-left: auto;" @click="changImageLabel">{{$t("cameraImg.Batchmodify")}}</el-button>
        </div>
        <!-- 600px -->
        <div class="table-box" ref="tableBox">
            <el-table
                :data="tableData"
                style="width: 100%"
                ref="table"
                @selection-change="checkedColumn"
                :max-height="maxHeight?maxHeight:tableHeight"
                v-loading="tableLoading"
                >
                <el-table-column align="center" type="selection" width="55"/>
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="device_number"
                    :label='$t("basic.public.deviceCoding")'
                    align="center"
                    width="220"
                >
                </el-table-column>
                <el-table-column
                    prop="event_type"
                    :label='$t("basic.public.eventType")'
                    align="center"
                    width="80"
                >
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.event_type == 0">{{ $t("basic.all.videoSys_online") }}</el-tag>
                        <el-tag type="info" v-if="scope.row.event_type == 1">{{ $t("basic.all.videoSys_downline") }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.event_type == 2">{{ $t("basic.all.videoSys_fault") }}</el-tag>
                        <el-tag type="danger" v-if="scope.row.event_type == 3">{{ $t("basic.all.videoSys_alarm") }}</el-tag>
                        <el-tag v-if="scope.row.event_type == 4">{{ $t("basic.all.videoSys_heartbeat") }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="event_code"
                    :label='$t("basic.all.videoSys_enven")'
                    align="center"
                    width="140"
                    >
                    <template slot-scope="scope">
                        <!-- <el-tag type="success" v-if="scope.row.event_type == 0">上线</el-tag>
                        <el-tag type="info" v-if="scope.row.event_type == 1">下线</el-tag> -->
                        <el-tag type="warning" v-if="scope.row.event_code == 0">{{ $t("basic.all.videoSys_heartbearEvent") }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.event_code == 1111">{{ $t("basic.all.videoSys_unknownEvent") }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.event_code == 1001">{{ $t("basic.all.videoSys_flameAlarm") }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.event_code == 1011">{{ $t("cameraImg.keyAlarm") }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.event_code == 1002">{{ $t("basic.all.videoSys_smokeAlarm") }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.event_code == 1003">{{ $t("basic.all.videoSys_flameSomkeAlarm") }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.event_code == 1005">{{ $t("cameraImg.Online") }} </el-tag>
                        <el-tag type="warning" v-if="scope.row.event_code == 1008">{{ $t("cameraImg.EVRA") }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.event_code == 1010">{{ $t("basic.all.videoSys_electrocarAlarm") }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.event_code == 1020">{{ $t("cameraImg.GarbageOverflow") }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.event_code == 1009">{{ $t("cameraImg.smoke433Alarm") }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.event_code == 1021">{{ $t("cameraImg.GarbageFull") }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.event_code == 1007">{{ $t("basic.all.videoSys_faceRecognition") }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.event_code == 2001">{{ $t("deviceDetail.Unorting") }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.event_code == 2002">{{ $t("deviceDetail.Oveting") }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.event_code == 2003">{{ $t("basic.all.videoSys_fault") }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.event_code == 2004">{{ $t("deviceDetail.remotePhotography") }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.event_code == 2005">{{ $t("cameraImg.ShockPhoto") }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.event_code == 2006">{{ $t("cameraImg.LowPhoto") }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.event_code == 2007">{{ $t("deviceDetail.photoTimer") }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.event_code == 1012">{{ $t("语音识别报警") }}</el-tag>
                        <!-- <el-tag type="danger" v-if="scope.row.event_type == 3">报警</el-tag> -->
                        <!-- <el-tag v-if="scope.row.event_type == 4">心跳</el-tag> -->
                    </template>
                </el-table-column>
                
                <el-table-column align="center" prop="label_name" :label="$t('cameraImg.TagName')" > 
                    <template slot-scope="scope">
                        {{ scope.row.label_name?scope.row.label_name:"--" }}
                    </template>
                </el-table-column>

                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="createTime"
                    :label='$t("basic.public.Two_modelOther")'
                    align="center"
                    >
                </el-table-column>

                <el-table-column
                    prop="url" 
                    :label='$t("basic.all.videoSys_img")'
                    align="center"
                    width="80"
                    >
                    <template slot-scope="scope">
                        <el-image
                            style="width: 30px; height: 30px"
                            :src="getURl(scope.row.url)"
                            :preview-src-list="srcList"
                            fit="fill">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <div class="mb3"><el-button type="primary" size="mini" @click="changeLabel(scope.row)">{{$t("cameraImg.ModifyTag")}}</el-button></div>
                        <div><el-button :type="!scope.row.playStatu?'success':'warning'" @click="togglePlay(scope.row)" size="mini" v-if="scope.row.pcm !== '' && scope.row.event_code==1012">{{!scope.row.playStatu?$t("cameraImg.playAudio"):$t("cameraImg.stopAudio")}}</el-button></div>
                    </template>
                </el-table-column>
            </el-table>
            <div ref="page" style="text-align: right;">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :current-page="page"
                    :page-size="15"
                    @current-change="changePage"
                    :total="total">
                </el-pagination>
            </div>
        </div>

        <el-dialog
            :append-to-body="true"
            :title="$t('cameraImg.ModifyImageTag')"
            :visible.sync="changeImgLabelDialog"
            width="390px"
            @close="closeDialog"
            >
            <el-form :model="form" :rules="rules" ref="ruleForm" >
                <el-form-item :label="$t('cameraImg.TagName')" :label-width="'100px'" prop="labelType">
                    <el-select v-model="form.labelType" :placeholder="$t('cameraImg.selectTagName')">
                        <el-option
                        v-for="item in labelTypeData"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="changeImgLabelDialog = false">{{$t("basic.public.cancel")}}</el-button>
                <el-button type="primary" @click="confirm">{{$t("basic.public.confirmBtn")}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import messageAll from '@/public_Js/messageAll';
import { pcm } from '@/utils/pcm';
export default{
    props:{
        device_number: {
            type: String,
            default: () => {
                return ""
            }
        },
        maxHeight:{
            type: String,
            default: () => {
                return ""
            }
        }
    },
    data(){
        return{
            tableData:[],
            time:[],
            deviceNum:'',
            srcList:[],
            total:0,
            page: 1,
            imgIds: [], // 多个修改
            imgId: [], // 单个修改
            changeImgLabelDialog: false, 
            form: {
                labelType: "",
            },
            tableLoading: false,
            eventCode: [
                { label: this.$t("basic.public.select_all"), value: -1 },
                { label: this.$t("basic.all.videoSys_heartbeat"), value: "0000" },
                { label: this.$t("cameraImg.Unknown"), value: "1111" },
                { label: this.$t("basic.all.videoSys_flameAlarm"), value: "1001" },
                { label: this.$t("cameraImg.keyAlarm"), value: "1011" },
                { label: this.$t("basic.all.videoSys_smokeAlarm"), value: "1002" },
                { label: this.$t("basic.all.videoSys_flameSomkeAlarm"), value: "1003" },
                { label: this.$t("cameraImg.EVRA"), value: "1008" },
                { label: this.$t("basic.all.videoSys_electrocarAlarm"), value: "1010" },
                { label: this.$t("cameraImg.Online"), value: "1005" },
                { label: this.$t("cameraImg.GarbageOverflow"), value: "1020" },
                { label: this.$t("cameraImg.GarbageFull"), value: "1021" },
                { label: this.$t("deviceDetail.Unorting"), value: "2001" },
                { label: this.$t("deviceDetail.Oveting"), value: "2002" },
                { label: this.$t("basic.all.videoSys_fault"), value: "2003" },
                { label: this.$t("deviceDetail.remotePhotography"), value: "2004" },
                { label: this.$t("cameraImg.ShockPhoto"), value: "2005" },
                { label: this.$t("cameraImg.LowPhoto"), value: "2006" },
                { label: this.$t("deviceDetail.photoTimer"), value: "2007" },
                { label: this.$t("语音识别报警"), value: "1012" },
            ],
            eventType: [
                { label: this.$t("basic.public.select_all"), value: -1 },
                { label: this.$t("cameraImg.Online"), value: 0 },
                { label: this.$t("basic.all.videoSys_downline"), value: 1 },
                { label: this.$t("basic.all.videoSys_fault"), value: 2 },
                { label: this.$t("basic.all.videoSys_alarm"), value: 3 },
                { label: this.$t("basic.all.videoSys_heartbeat"), value: 4 },
            ],
            rules: {
                labelType: [{required: true, message: this.$t('cameraImg.selectTagName'), trigger: 'blur'}]
            },
            labelTypeData: null,
            params: {
                LabelType: "",
                EventType: -1,
                EventCode: -1,
                start_time: this.$TOOL.dateFormat() + " 00:00:00",
                end_time:  this.$TOOL.dateFormat() + " 23:59:59",
            },
            tableHeight: "300px",
            labelData: null,
            playId: "",
        }
    },
    
    watch:{
        // device_number(newValue){
        //     this.getTable(newValue);
        // },
        deep: true
    },
    created() {
    },
    mounted() {
        // this.getTable(this.device_number)
        this.time = [this.$TOOL.dateFormat(),this.$TOOL.dateFormat()]
        window.addEventListener("resize", this.windowWinthChang)
        this.getLabelData()
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.windowWinthChang);
    },
    methods: {
        // 切换音频播放
        togglePlay(row) {
            if (this.playId === row.id) {
                row.playStatu = false;
                pcm.stopPlay(row);
                this.playId = "";
            } else {
                this.playId = row.id;
                pcm.beginPlay(row);
                this.tableData.forEach(item => {item.playStatu = false})
                row.playStatu = true;
            }
        },
        searchImg() {
            this.page = 1;
            this.getTable(this.device_number)
        },
        // 清除搜索框内容
        clearmSearch() {
            this.params = {
                LabelType: "",
                EventType: -1,
                EventCode: -1,
                start_time: this.$TOOL.dateFormat(null, "yyyy-MM-dd hh:mm:ss"),
                end_time: this.$TOOL.dateFormat(null, "yyyy-MM-dd hh:mm:ss"),
            }
            this.time = [];
            this.getTable(this.device_number);
        },
        // 获取标签数据内容
        getLabelData() {
            let data = {
                page: 1,
                limit: 100,
            }
            this.$API.cameraLabel.queryLabelType.get(data).then( res => {
                if (res.code == 200) {
                    let data = res.data.data;
                    data.unshift({id: "", name: this.$t("basic.public.select_all")})
                    this.labelData = res.data.data;
                }
            })
        },
        windowWinthChang() {
            if (this.$refs.infoBox) {
                let h1 = this.$refs.tableBox.clientHeight;
                let h2 = this.$refs.page.clientHeight;
                this.tableHeight = `${h1 - h2 * 2}px`;
            }
        },
        async getTable(num) {
            this.tableLoading = true;
            let data = {
                page: this.page,
                limit: 15,
                device_number: num,
                ...this.params
            }
            console.log("12312312", num)
            this.srcList = [];
            this.$API.cameraLabel.getAllCameraEvent.get(data).then( res => {
                if( res.code == 200 ){
                    let data = res.data.data;
                    data.forEach(item => {item.playStatu = false})
                    this.tableData = data;
                    if( this.page == 1 )  this.total = res.data.count;
                    if(data && data.length > 0){
                        data.forEach(item => {
                            this.srcList.push(this.getURl(item.url))
                        });
                    }
                }
            }).finally(() => {
                this.tableLoading = false;
                this.windowWinthChang();
            })
        },
        checkedColumn(val) {
            this.imgIds = val;
        },
        changePage(val){
            this.srcList = [];
            this.page = val
            this.getTable(this.device_number)
        },
        getURl(url){
            return this.$TOOL.getMediaUrl(url)
        },
        changeTime(e){
            this.srcList = [];
            this.params.start_time = e[0] + " 00:00:00"
            this.params.end_time = e[1] + " 23:59:59"
            this.page = 1;
            this.getTable(this.device_number)
            
        },
        // 修改图片标签
        changImageLabel() {
            if( this.imgIds.length === 0 ){
                messageAll.warningOpen(this.$t("cameraImg.NISFM"));
                return;
            }
            this.getLabelTypeData();
            this.changeImgLabelDialog = true;
            this.changType = "batch"; // 批量修改
        }, 
        // 单个修改
        changeLabel(value) {
            this.getLabelTypeData();
            this.changType = "single"; // 单个修改
            this.imgId = [value.id];
            this.changeImgLabelDialog = true;
        },
        closeDialog() {
            this.form.labelType = "";
            this.$refs.ruleForm.clearValidate()
        },
        getLabelTypeData() {
            let data = {
                page: 1,
                limit: 100,
            }
            this.$API.cameraLabel.queryLabelType.get(data).then( res => {
                if( res.code == 200 ){
                    this.labelTypeData = res.data.data;
                }
            })
        },
        // 确认修改
        confirm() {
            let Ids = this.imgIds.map( item => item.id ) 
            let data = {
                LabelType: this.form.labelType,
                CameraEvent: this.changType == "batch" ? Ids : this.imgId,
            }

            this.$refs.ruleForm.validate(valid => {
                if( valid ){
                    this.$API.cameraLabel.updateCameraEventLabel.post(data).then(res => {
                        if( res.data.code == 200 ){
                            messageAll.successOpen(this.$t("basic.public.message_editSucc"));
                            this.changeImgLabelDialog = false;
                            this.getTable(this.device_number);
                            this.$refs.table.clearSelection();
                            if (this.changType == "batch") { 
                                // this.$refs.table.clearInputValue();
                            }
                            this.imgIds = [];
                            return
                        }
                        messageAll.warningOpen(this.$t("basic.deviceList.basice_editLose"));
                    })
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.infoBox{
    width: 100%;
    height: 100%;
    font-size: 12px;
}
.detailHeader{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
}
.table-box{
    height: calc(100% - 120px);
}
::v-deep .el-date-range-picker{
    position: absolute !important;
}
::v-deep .el-popover{
    position: absolute !important;
}
.labeltitle{
    margin-right: 10px;
}
.select-box {
    margin-right:10px;
    margin-bottom: 10px;
    position: relative;
    ::v-deep .el-select-dropdown{
        position: absolute !important;
        top: 0;
        left: 0;
    }
}
</style>